<template lang="pug">
.empty-content(:class="onlyOffers && '_offers'")
  .inner-content
    h2(v-if="!onlyOffers") У Вас нет заказанных услуг
    .message(v-if="!onlyOffers")
      | Наши специалисты надежно и своевременно оказывают консалтинговые услуги, повышая эффективность и результативность наших клиентов – Поставщиков и Заказчиков в сфере участия и проведения всех видов закупок
    .special-offers

      .info-block(style="grid-area: app-prepare")
        .title Подготовка заявок по закупкам
        span Оперативно и качественно подготовим заявки для подачи к закупкам. Будьте уверены в успешном допуске Вашей заявки даже в случае сложных и избыточных требований заказчиков

      .info-block(style="grid-area: expert-search")
        span.hit Уникальная услуга
        .title Экспертный поиск тендеров
        span Поиск экспертом-аналитиком тендеров по Вашим критериям
        span Используем нейронную сеть на базе системы поиска ТендерМонитор
        span Получите интересные Вам тендеры, без мусора и лишней работы

      .info-block(style="grid-area: law-consulting")
        .title Юридические консультации
        span По запросу проанализируем тендерную документацию и вашу заявку на предмет соответствия действующему законодательству, оперативно внесем корректировки

      .info-block(style="grid-area: legal-protection")
        .title Юридическая защита
        span Защита интересов в УФАС
        span Составление соглашений
        span Работа по расторжению контрактов
        span Досудебная претензионная работа
        span Судебные споры

      .info-block(style="grid-area: complaints")
        .title Работа по жалобам
        span Жалоба в УФАС 44-ФЗ, 223-ФЗ
        span Возражения на жалобу в УФАС по 44-ФЗ, 223-ФЗ
        span Пояснения в УФАС по одностороннему отказу заказчика (признанию участника уклонившимся от заключения контракта)

      .info-block(style="grid-area: litigation")
        .title Судебные споры
        span Оформление исковых заявлений, отзывов на исковое
        span Оформление апелляционных, кассационных жалоб
        span Оформление заявлений об обеспечении иска
        span Представительство на судебном заседании

      .info-block(style="grid-area: claim-work")
        .title Претензионная работа
        span Составление писем и ответов заказчику
        span Составление дополнительных соглашений
        span Расчёт штрафов/пеней по контракту
        span Подготовка досудебной претензии
        span Документы об отказе от исполнения
        span Возражение на отказ контрагента

  .inner-content(v-if="!onlyOffers")
    .service-order-form
      services-selector.service-selector(required v-model="service")
      order-form(
        show-textarea
        v-model="form"
        ref="orderFormRef"
        @confirm="createOrder"
      )
      agreement(mode="info")
      ui-button.order-button(:loader="isSaving" @click.stop="validate") Заказать

</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useOrderForm } from "@/use/tenderService/useOrderForm";

import UiButton from "@/components/ui/button/UiButton.vue";
import OrderForm from "@/components/orderForm/OrderForm.vue";
import ServicesSelector from "@/components/selector/ServicesSelector.vue";
import Agreement from "@/components/authorization/dialogContent/Agreement.vue";

export default defineComponent({
  name: "ServiceAdvertising",
  components: {
    UiButton,
    Agreement,
    OrderForm,
    ServicesSelector,
  },
  props: {
    onlyOffers: {
      type: Boolean,
      default: false,
    },
  },
  setup() {

    const {
      form,
      service,
      isSaving,
      orderFormRef,
      validate,
      createOrder,
    } = useOrderForm();

    return {
      form,
      service,
      isSaving,
      orderFormRef,
      validate,
      createOrder,
    }
  }
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/forms/orderForm";

.empty-content {
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-column-gap: 32px;

  &._offers {
    grid-template-columns: 1fr;
  }

  .inner-content {
    display: flex;
    flex-flow: column;
    gap: 24px;

    h2 {
      font-size: 20px;
    }

    .message {
      font-size: 16px;
    }

    .service-order-form {
      position: sticky;
      top: 24px;
      display: flex;
      flex-flow: column;
      gap: 16px;
    }
  }
}

.special-offers {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
    "expert-search         expert-search     expert-search"
    "legal-protection      claim-work        claim-work"
    "legal-protection      litigation        litigation"
    "complaints            app-prepare       law-consulting";
  gap: 20px;
}

.info-block {
  display: flex;
  flex-flow: column;
  gap: 8px;
  border: 1px solid #409eff;
  border-radius: 12px;
  padding: 16px;

  .title {
    font-size: 18px;
    font-weight: 600;
    color: #324059;
  }

  span {
    font-size: 14px;
    line-height: 20px;
    color: var(--secondary-text-color);

    &.hit {
      font-size: 12px;
      color: var(--main-red-color);
      text-transform: uppercase;
    }
  }
}
</style>
